<template>
  <div class="active_index">
    <img src="../../assets/images/active/logo.png" class="header" alt="">

    <div class="tips">
      <p>    <img src="../../assets/images/active/icon.png"  alt=""> <span>提交成功</span></p>
      <p>欢迎使用蓝灯鱼</p>
    </div>

    <div class="discounts">
      <p class="discount_title">优惠活动</p>
      <ul class="discounts_content">
        <li><span class="span_1">商标AI图文查询1个月</span> <span class="span_2"><img style="width: 1.1rem" src="../../assets/images/active/huo1.png" alt=""></span></li>
        <li><span class="span_1">活动期间下单购买产品</span> <span class="span_2"><img style="width: 1.1rem" src="../../assets/images/active/huo2.png" alt=""></span></li>
        <li><span class="span_1">同时关注公众号购买产品</span> <span class="span_2"><img style="width: 1.1rem" src="../../assets/images/active/huo3.png" alt=""></span></li>
      </ul>
    </div>

    <div class="follow">
      <div class="follow_dec">
        <p>扫码关注</p>
        <p>长按识别二维码</p>
        <p>所有用户活动期间(2021.3.25-4.30)登录或注册，即可参与</p>
      </div>
      <div>
        <img src="../../assets/images/active/sao.png" class="sao" alt="">
      </div>
    </div>

    <!--    <div style="width: 100%;text-align: center;margin-top: 0.74rem">-->
    <!--      <p style="font-size: 0.24rem;color: black">您可以享受以下优惠</p>-->
    <!--    </div>-->

    <!--    <div style="margin-top: 0.65rem">-->
    <!--&lt;!&ndash;      <img style="width: 100%;height: 1.74rem" src="../../assets/img/discount.png" class="header" alt="">&ndash;&gt;-->
    <!--    </div>-->

    <!--    <div style="width: 100%;text-align: center;margin-top: 1.13rem">-->
    <!--      <p style="font-size: 0.24rem;color: black">更多优惠，请前往优选字符网站</p>-->
    <!--    </div>-->

    <!--    <div style="width: 100%;text-align: center;margin-top: 0.45rem;height: 0.48rem">-->
    <!--      <van-button @click="jump" class="website" >http://www.youxuanzifu.com</van-button>-->
    <!--    </div>-->

    <!--    <div class="bottomsss">-->
    <!--      <div style="display: flex;flex-direction: column;align-items: center">-->
    <!--&lt;!&ndash;        <img @click="exhibit(0)" style="width: 1.5rem;height: 1.5rem;margin-top: 0.34rem" src="../../assets/img/part1.jpg" class="header" alt="">&ndash;&gt;-->
    <!--        <span style="font-size: 0.23rem;color: black;margin-top: 0.2rem">微信客服号</span>-->
    <!--      </div>-->

    <!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
    <!--&lt;!&ndash;        <img @click="exhibit(1)" style="width: 1.5rem;height: 1.5rem;margin-top: 0.34rem" src="../../assets/img/part2.jpg" class="header" alt="">&ndash;&gt;-->
    <!--        <span style="font-size: 0.23rem;color: black;margin-top: 0.2rem">微信公众号</span>-->
    <!--      </div>-->
    <!--    </div>-->


    <!--    <van-overlay :show="show" >-->
    <!--      <div class="wrapper" @click="close">-->
    <!--        <div class="block" @click.stop>-->
    <!--&lt;!&ndash;          <img v-if="whichOne == 0"  style="width: 4rem;height: 4rem" src="../../assets/img/part1s.png" class="header" alt="">&ndash;&gt;-->

    <!--&lt;!&ndash;        <img v-else @click="exhibit" style="width: 4rem;height: 4rem" src="../../assets/img/part2s.png" class="header" alt="">&ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </van-overlay>-->

  </div>
</template>
<script>
  import { wechatRedirect , wechatConfig } from "@/request/active";
  import { Toast,Notify } from 'vant';
  export default {
    name: 'index',
    data(){
      return {
        show: false,
        //展示哪个结果
        whichOne: 0,
        userInfo:'',
        username: '',
        company: '',
        iphone: '',
        code: '',
        random:'',
        isTime: true,
        time: 60 * 1000,
      }
    },
    mounted(){
      document.title = '蓝灯鱼';
      // if(this.$cookie.get('openId')){
      //   this.getUserInfo();
      // }
    },
    methods:{
      close(){
        this.show = false
      },
      exhibit(item){
        this.whichOne = item
        this.show = true
      },
      //点击跳转优选字符移动端
      jump(){
        window.location.href = 'http://www.youxuanzifu.com/cn';
      },


      uuid() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
      },
      //倒计时结束触发
      finish(){
        this.isTime = true
      },
      //发送验证码
      verification(){
        // this.getCheck({
        //   username: this.iphone,
        //   authType: 'sms'
        // }).then((res) => {
        //   console.log(res)
        //   // this.$toast.success(response.data.msg)
        //   // this.$router.push('/activitySuccess')
        // })
        // return;
        this.random = this.uuid()
        wechatRedirect({
          subject: this.iphone,
          token: this.random,
          locale: 'zh',
          bizType: 1
        }).then((response)=>{
          // eslint-disable-next-line no-empty
          if(response.msg == 'success'){
            this.isTime = false
            Toast.success(response.data);
          }else {
            Toast.fail(response.data);
          }
        });
      },
      recharge(){
        this.$router.push('/pay')
      },
      activity(){
        this.$router.push('/activity')
      },
      submit(values) {

        // 全局表单验证
        this.$refs.form.validate().then(() => {
          // this.$toast.success('提交成功')
          wechatConfig({
            username: this.username,
            countryName: this.company,
            phone: this.iphone,
            code: this.code,
            token: this.random
          }).then((response)=>{
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line no-unused-vars
            if(response.code == 0 || response.code == 10001){
              this.$toast.success('注册成功')
              this.isTime = true
              // 检测用户是否注册过
              // this.$router.push({
              //   path:'/activitySuccess',
              //   query:{
              //     id:response.code == 10001 ? '1' : '0'
              //   }
              // })
            }else {
              this.$toast.fail('提交失败')
              Notify({ type: 'warning', message: response.msg });
            }

          });
        }).catch(() => {
          this.$toast.success('提交失败')
        })


        // console.log('submit', values);
      }
    }
  }
</script>
<style lang="less">

  .active_index{
    width: 100%;
    height:100%;
    overflow: hidden;
    background-color: #3d76b6;

    .tips{
      margin: 0 auto;
      color: white;
      width: 5rem;
      text-align: center;
      p:nth-of-type(1) {
        margin-top: 10px;
        font-size: 0.40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 0.4rem;
          height: 0.4rem;
          display: block;
          margin-right: 0.2rem;
        }
        span{
          display: block;
        }
      }
      p:nth-of-type(2) {
        margin-top: 10px;
        font-size: 0.33rem;
      }
    }
    .field{
      border: 1px solid #ffffff!important;
      border-radius: 10px 10px!important;
      position: relative!important;
      overflow: visible!important;
      margin-bottom: 0.3rem!important;
    }

    .fields{
      padding-top: -0.2rem!important;
      border-radius: 10px 10px!important;
      position: relative!important;
      overflow: visible!important;

    }

    .fields .van-field__body {
      margin-top: 1px!important;
      color: white!important;
      background-color: #3d76b6!important;
    }

    .yan{
      position: relative;
    }

    .follow{
      width: 6.4rem;
      display: flex;
      border-radius: 10px 10px;
      background-color: #498dc8;
      padding: 0.3rem 0.35rem 0.23rem 0.44rem;
      box-sizing: border-box;
      justify-content: space-between;
      margin: 0.78rem auto 0.83rem;
      .follow_dec{
        p:nth-of-type(1){
          font-size: 0.27rem;
          color: #d9c49c;
        }

        p:nth-of-type(2){
          font-size: 0.21rem;
          color: #ffffff;
          margin-top: 0.15rem;
          padding-bottom: 0.09rem;
          width: 3.24rem;
          box-sizing: border-box;
          border-bottom: 1px solid #609cd1;
        }

        p:nth-of-type(3){
          margin-top: 0.08rem;
          font-size: 0.21rem;
          color: #ffffff;
          width: 3.8rem;
        }

      }
      img{
        width: 1.65rem;
      }
    }


    .yan:before{
      position: absolute;
      content: '';
      background: white;
      width: 2px;
      height: 0.5rem;
      top: -0.08rem;
      left: -0.3rem;
    }

    .van-cell__title{
      width: 1.3rem!important;
      position: absolute!important;
      left: 0.32rem!important;
      top: -0.23rem!important;
      background-color: #3d76b6!important;
      color: white!important;
      padding-left: 0.13rem!important;
      font-size: 0.23rem!important;
    }

    .header{
      height: 1.4rem;
      margin: 1rem auto 0.7rem;
      display: block;
    }

    .discounts{
      width: 6.4rem;
      margin: 0 auto;
      position: relative;
      margin-top: 0.76rem;
      padding: 0.28rem 0.44rem 0.27rem 0.9rem;
      box-sizing: border-box;
      border-radius: 0.5rem 0.5rem;
      border: 1px solid #ffffff;
      .discount_title{
        font-size: 0.32rem;
        position: absolute;
        color: #ffffff;
        top: -0.25rem;
        left: 0.4rem;
        background-color: #3d76b6;
        width: 1.8rem;
        text-align: center;
      }
      .discounts_content{
        color: white;
        font-size: 0.29rem;

        li{
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          /*margin-bottom: 1.14rem;*/
          .span_1{
            /*display: flex;*/
            /*align-items: center;*/
            display: block;
            /*line-height: 1.06rem;*/
            padding-top: 0.31rem;

          }
          .span_2{
            width: 1.07rem;
            height: 1.07rem;
            padding: 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50% 50%;
            background-color: #d9c49c;
            font-size: 0.26rem;
            box-sizing: border-box;
            font-weight: bold;
          }
        }
      }
    }

    .discount{
      width: 100%;

    }


    .submit{
      width: 6.4rem!important;
      height: 0.68rem;
      line-height: 0.68rem;
      border-radius: 12px 12px!important;
      margin: 0 auto;
      margin-top: 0.5rem;
      font-weight: bold;
      color: #0a4596!important;
      font-size: 0.25rem;
      background-color: #ffffff!important;
    }

    .van-cell::after{
      border-bottom: none!important;
    }

    .van-field__error-message{
      font-size: 0.19rem!important;
      margin-top: 0.1rem!important;
    }

    .van-field__body {
      /*border-bottom: 1px solid #ebedf0;*/
      white-space: nowrap;
      /*margin-top: -0.05rem;*/
    }

    .van-field__control{
      color: #ffffff!important;
      font-size: 0.22rem!important;
    }

    .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
      color: #ffffff!important;
    }

    .van-field__body input::-webkit-input-placeholder{
      color: #323233!important;
      font-size: 0.22rem!important;
    }
    .van-field__body input::-moz-placeholder{   /* Mozilla Firefox 19+ */
      color: #323233!important;
      font-size: 0.22rem!important;
    }
    .van-field__body input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
      color: #323233!important;
      font-size: 0.22rem!important;
    }
    .van-field__body input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
      color: #323233!important;
      font-size: 0.22rem!important;
    }
    .van-field__body input{
      font-size: 0.25rem!important;
    }


    .van-field__label {
      margin-right: 0px!important;
      width: 1.2rem!important;
      font-size: 0.23rem;
    }

  }

</style>
